<template>
  <v-container>
    <v-card flat>
      <v-toolbar
        dense
        height="40"
        color="transparent"
        elevation="1"
      >
        <h3 class="blue--text">
          PROSPECTO
        </h3>
      </v-toolbar>
      
      <v-row>
        <v-col>
          <v-checkbox
            v-model="acc1"
            dense
            label="Visita"
            hide-details
          />
          <v-checkbox
            v-model="acc2"
            dense
            label="Zoom"
            hide-details
          />
          <v-checkbox
            v-model="acc1"
            dense
            label="Mensaje"
            hide-details
          />
          <v-checkbox
            v-model="acc1"
            dense
            label="Llamada"
            hide-details
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          lg="12"
          md="6"
          xs="12"
          sm="12"
        >
          <v-toolbar
            flat
            color="transparent"
            dense
            height="40"
          >
            <h3>Acciones en la visita</h3>
          </v-toolbar>


          <v-checkbox
            v-model="acc7"
            label="Se hablo con el decisor"
            dense
            hide-details
          />

          <v-textarea
            auto-grow
            outlined
            rows="3"
            row-height="15"
            class="mx-1 mt-2"
            dense
            hide-details
            label="Situacion actual"
          />

          <v-textarea
            auto-grow
            outlined
            height="50"
            class="mx-1 mt-2"
            dense
            hide-details
            label="Problemas con el prospecto"
          />

          <v-textarea
            outlined
            auto-grow
            height="50"
            class="mx-1 mt-2"
            dense
            label="Volumen aproximado"
            hide-details
          />

          <v-checkbox
            v-model="acc8"
            dense
            label="Es prospecto potencial"
            hide-details
          />

          <v-checkbox
            v-model="acc9"
            dense
            label="Se hizo una presentacion"
            hide-details
          />

          <v-checkbox
            v-model="acc10"
            dense
            label="Se realizo una propuesta"
            hide-details
          />

          <v-checkbox
            v-model="acc5"
            dense
            label="Levantamiento de informacion"
            hide-details
          />

          <v-checkbox
            v-model="acc5"
            dense
            label="Seguimiento a pruebas"
            hide-details
          />
        </v-col>

        <v-col
          cols="12"
          lg="12"
          md="6"
          xs="12"
          sm="12"
        >
          <v-toolbar
            flat
            color="transparent"
            dense
            height="40"
          >
            <h3>Resultado</h3>
          </v-toolbar>

          <v-row>
            <v-col>
              <v-checkbox
                v-model="acc5"
                dense
                label="Se levanto pedido"
                hide-details
              />
              <v-text-field
                dense
                label="Folio"
                hide-details
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-checkbox
                v-model="acc5"
                label="Se entrego cotización"
                hide-details
                dense
              />
              <v-text-field
                dense
                label="Folio"
                hide-details
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-checkbox
                v-model="acc5"
                dense
                label="Se atendio pendiente"
                hide-details
              />
              <v-text-field
                dense
                label="Folio"
              />
            </v-col>
          </v-row>


          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                label="Proxima visita"
                prepend-inner-icon="mdi-calendar"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="date"
              scrollable
              locale="es"
            >
              <v-spacer />
              <v-btn
                text
                color="primary"
                @click="modal = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
           
          <v-menu
            ref="menu"
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="time"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="time"
                label="Hora de Inicio"
                prepend-inner-icon="mdi-clock-time-four-outline"
                readonly
                dense
                outlined
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-time-picker
              v-if="menu2"
              v-model="time"
              full-width
              @click:minute="$refs.menu.save(time)"
            />
          </v-menu>
          
          <v-menu
            ref="menu"
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="time"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="time"
                label="Hora de termino"
                prepend-inner-icon="mdi-clock-time-four-outline"
                readonly
                dense
                outlined
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-time-picker
              v-if="menu2"
              v-model="time"
              full-width
              @click:minute="$refs.menu.save(time)"
            />
          </v-menu>
           

          <v-textarea
            outlined
            rows="3"
            row-height="15"
            label="Notas para recordar"
            hide-details
            dense
            auto-grow
          />
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      checkbox1: false,
      checkbox2: false,
      acc1:'',
      acc2:'',
      acc3:'',
      acc4:'',
      acc5:'',
      acc6:'',
      acc7:'',
      acc8:'',
      acc9:'',
      acc10:'',
      acc11:'',
      acc12:'',

      time: null,
      // menu2: false,
      modal2: false,

      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
    }
  },
}
</script>